import React from "react"
import Layout from "../components/Layout"

export default function Fourofour() {
    return (
        <Layout>
          <tr>
            <td width="250" rowSpan="2" valign="bottom">
              <img
                src="/bereiche.jpg"
                alt="T&auml;tigkeitsbereiche"
                style={{ display: "block" }}
              />
            </td>
            <td width="480" height="50" valign="middle" align="center" colSpan="3">
              <h1>Die von Ihnen angefragte Seite kann nicht gefunden werden</h1>
            </td>
          </tr>
        </Layout>
    )
}